import React, { useContext, useState } from 'react'
import { navigate, graphql } from 'gatsby'
import Form from 'components/form'
import { REGISTER_USER } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "../context/context"
import RegisterBackground from '../images/register-background2.png'
import SEO from 'components/seo'

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                contactPage {
                    heading
                    address
                    phoneNumber
                    emailAddress
                    pressEmail
                    pressPhoneNumber
                    researchEmail
                    researchPhoneNumber
                }
            }
            form(id: "Zm9ybTo5MjU") {
                ...FormFieldsFragment
            }
        }
    }
`

export default ({
    data,
    location,
}) => {
    const {
        options: {
            copy: {
                registerPageSideCopy
            }
        }
    } = useContext(MainContext)

    const {
        form
    } = data.siteFields

    const [registerUser] = useMutation(REGISTER_USER)
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [registerError, setRegisterError] = useState(null)

    const handleSubmit = _data => {

        if (window.qrCodeData) {
            window.qrCodeData = ''
        }

        setIsFormLoading(true)

        const json = JSON.stringify(_data)

        registerUser({
            variables: {
                data: json
            },
        }).then(({ data }) => {
            setIsFormLoading(false)
            const { user } = data.registerUserAndProduct

            window.localStorage.setItem("user", JSON.stringify(user))

            navigate('/portal',{
                state: {
                    userId: user.id,
                },
            })
        }).catch(error => {
            setIsFormLoading(false)

            if(typeof window !== 'undefined'){
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }

            setRegisterError('That email appears to be taken.')
        })
    }

    return (
        <>
            <SEO title="Register your product" />
            <div className="register-page">
                <div className="container">
                    <div className="grid">
                        <div className="column">
                            <Form 
                                form={form}
                                handleSubmit={handleSubmit}
                                isLoading={isFormLoading}
                                error={registerError}
                                location={location}
                            />
                        </div>
                        <div className="column">
                            <h2>
                                { registerPageSideCopy}
                            </h2>
                            <img className="bg" src={RegisterBackground} alt="Register" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}